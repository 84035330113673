import React from 'react';
import ContentPage from '../../components/ContentPage';
import PageImage from '../../components/PageImage';
import LinksList from '../../components/LinksList';

import image from '../../images/undraw_real_time_sync.svg';

function ServicesDevelopmentPage() {

    const navLinks = [
        {label: 'Web Applications', route:'../webapps'},
        {label: 'Mobile Apps', route:'../mobileapps'},
        {label: 'Websites', route:'../websites'},
    ]

    return <ContentPage>
        <h1>Application Development</h1>
        <p>Our speciality is building applications for the web.  With the increasing diversity of 
            devices where applications are running it no longer makes sense to focus on desktop 
            applications that are tied to a PC.</p>
        <PageImage image={image} alt='Application Development' />
        <p>The move to regular working at home has also shown us the need to be able to access our applications from 
            anywhere.</p>
        <p>Coralpeak Systems can help you to move your business to the web and free your users from the office.</p>
        <LinksList links={navLinks} caption='Find out more about the systems that we can develop for you:' />

    </ContentPage>

}

export default ServicesDevelopmentPage;